import React, { Component } from 'react';
import { 
    ShimmeredDetailsList, 
    DetailsListLayoutMode,
    CommandBar, 
    Selection, 
    Modal, 
    IconButton, 
    PrimaryButton, 
    DefaultButton, 
    TextField,
    Text,
    Dialog, 
    DialogType, 
    DialogFooter,
    Label
} from '@fluentui/react';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import '../css/adminlayout.css';
import { AdminHeader } from '../components/admin-header';
import { Category } from '../utilities/category';
import $ from 'jquery';

initializeIcons();

const DayPickerStrings = {
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    goToToday: 'Ir a hoy',
    prevMonthAriaLabel: 'Ir al mes anterior',
    nextMonthAriaLabel: 'Ir al mes siguiente',
    prevYearAriaLabel: 'Ir al año anterior',
    nextYearAriaLabel: 'Ir al año siguiente',
    closeButtonAriaLabel: 'Cerrar calendario'
};

export class AdminCategories extends Component {
    displayName = AdminCategories.name;
    _selection;
    oldCatedgory = '';

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => this.getSelectionDetails()
        });

        this.state = {
          data: [],
          hasSelectedItems: false,
          disableEdit: true,
          disableDelete: true,
          showNewModal: false,
          showDeleteDialog: false,
          category: new Category(),
          modalTitle: '',
          loading: true,
          editMode: false
        };

        const authenticated = Common.getCookie('authenticated');

        if(authenticated == '') {
            window.location.href = '/admin';
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='admin-data'>
                <AdminHeader/>
                <Text variant='xxLargePlus' block className='super-title'>Categorías</Text>
                <CommandBar
                    items={this.getCommandBarItems()}
                />
                <ShimmeredDetailsList
                enableShimmer={this.state.loading}
                items={this.state.data}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={this.onRenderItemColumn.bind(this)}
                columns={
                    [
                        {
                            key: 'name',
                            name: 'Nombre',
                            fieldName: 'name',
                            minWidth: 200,
                            maxWidth: 200,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'image',
                            name: 'Imagen de fondo',
                            fieldName: 'background',
                            minWidth: 200,
                            maxWidth: 200,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'video',
                            name: 'Video',
                            fieldName: 'video',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        }
                    ]
                }/>
                <Modal
                    containerClassName='modal-large'
                    isOpen={this.state.showNewModal}
                    onDismiss={() => {
                        this.setState({
                            showNewModal: false
                        });
                    }}
                    isBlocking={true}>
                    <div className='modal-header'>
                        <span>
                            {
                                this.state.modalTitle
                            }
                        </span>
                        <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        className='modal-close'
                        onClick={() => {
                            this.setState({
                                showNewModal: false
                            });
                        }}
                        />
                    </div>
                    <div className='modal-content'>
                        <TextField label='Nombre' defaultValue={this.state.category.name} onChange={(ev, newValue) => {
                            const category = this.state.category;
                            category.name = newValue;
                            this.setState(
                                {
                                    category: category
                                }
                            );
                        }}></TextField>
                        <div className='gallery-item' style={{float: 'none'}}>
                            <Label>Imagen de fondo</Label>
                            <div className='admin-image' style={{background: `url('${this.state.category.background}')`}} onClick={() => {
                                $(`#file`).click();
                            }}/>
                            <input type='file' id='file' onChange={() => {
                                this.getFile();
                            }}/>
                        </div>
                        <TextField label='URL del vídeo' defaultValue={this.state.category.video} onChange={(ev, newValue) => {
                            const category = this.state.category;
                            category.video = newValue;
                            this.setState(
                                {
                                    category: category
                                }
                            )
                        }}></TextField>
                    </div>
                    <div className='modal-footer'>
                        <PrimaryButton text='Guardar' onClick={this.saveCategory.bind(this)}></PrimaryButton>
                        <DefaultButton text='Cancelar' onClick={() => {
                        this.setState({
                            showNewModal: false
                        });
                        }}></DefaultButton>
                    </div>
                </Modal>
                <Dialog
                    hidden={!this.state.showDeleteDialog}
                    onDismiss={() => {
                        this.setState({
                            showDeleteDialog: !this.state.showDeleteDialog
                        });
                    }}
                    dialogContentProps={
                        {
                            type: DialogType.normal,
                            title: 'Eliminar actividades',
                            subText: '¿Deseas eliminar las actividades seleccionadas?'
                        }
                    }
                    modalProps={
                        {
                            isBlocking: true
                        }
                    }
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            this.deleteActivities(0, () => {
                                this.getData();
                            });
                        }} text="Eliminar" />
                        <DefaultButton onClick={() => {
                            this.setState({
                                showDeleteDialog: !this.state.showDeleteDialog
                            });
                        }} text="Cancelar" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    getCommandBarItems() {
        return [
            {
                key: 'newItem',
                text: 'Nuevo',
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    this.setState(
                        {
                            category: new Category(),
                            modalTitle: 'Nueva actividad',
                            editMode: false,
                            showNewModal: true
                        }
                    )
                }
            },
            {
                key: 'editItem',
                text: 'Editar',
                iconProps: { iconName: 'Edit' },
                disabled: this.state.disableEdit,
                onClick: () => {
                    this.setState(
                        {
                            modalTitle: 'Editar actividad',
                            editMode: true,
                            category: this._selection.getSelection()[0],
                            showNewModal: true
                        }
                    )
                }
            },
            {
                key: 'delete',
                text: 'Eliminar',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    this.setState(
                        {
                            showDeleteDialog: !this.state.showDeleteDialog
                        }
                    )
                },
                disabled: this.state.disableDelete
            },
            {
                key: 'view',
                text: 'Ver en la web',
                iconProps: { iconName: 'View' },
                onClick: () => {
                    window.open(`/category?a=${this._selection.getSelection()[0].id}`)
                },
                disabled: this.state.disableEdit
            }
        ]
    }

    getSelectionDetails() {
        this.oldCatedgory = this._selection.getSelectedCount() > 0 ? this._selection.getSelection()[0].name : '';
        this.setState({
            hasSelectedItems: this._selection.getSelectedCount() > 0,
            disableEdit: this._selection.getSelectedCount() !== 1,
            disableDelete: this._selection.getSelectedCount() === 0
        });
    }

    getData() {
        this.setState({
            loading: true
        });
        
        const manager = new FirebaseManager();
        manager.getData((data) => {
            const result = Object.assign([], data.categories);
            const parsedResult = [];
            
            for(let i = 0; i < result.length; i++) {
                if (result[i] != null) {
                    parsedResult.push(result[i]);
                }
            }

            this.setState(
                {
                    data: parsedResult,
                    showNewModal: false,
                    showDeleteDialog: false,
                    category: new Category(),
                    loading: false
                }
            );
        })
    }

    _onFormatDate (date) {
        if(date != null) {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
    
          return `${day}/${month}/${year}`;
        }
        else {
          return '';
        }
    }

    saveCategory() {
        const category = this.state.category;
        const oldCategory = this.oldCatedgory;
        console.log(oldCategory);
        
        const manager = new FirebaseManager();
        
        if (!this.state.editMode) {
            manager.addCategory(category, () => {
                this.getData();
            }); 
        }
        else {
            manager.editCategory(category, this._selection.getSelectedIndices()[0], () => {
                manager.getActivities((data) => {
                    this.updateCategory(data, oldCategory, category.name, 0);
                    this.getData();
                });
            }); 
        }
    }

    updateCategory(activities, oldCategory, newCategory, index) {
        let activity = activities[index];
        
        if(index == activities.length || activity == null) {
            return;
        }

        for(let i = 0; i < activity.category.length; i++) {
            const indexOf = activity.category.indexOf(oldCategory);

            if(indexOf > -1) {
                const categories = activity.category;
                categories.splice(indexOf, 1);
                categories.push(newCategory);
                activity.category = categories;
                console.log(activity);
                break;
            }
        }

        const manager = new FirebaseManager();
        manager.editActivity(activity, () => {
            const newIndex = index + 1;
            this.updateCategory(activities, oldCategory, newCategory, newIndex);
        });
    }

    deleteCategories(index, callback) {
        const category = this._selection.getSelection()[index];
        
        if (category == null) {
            callback();
            return;
        }

        console.log(category);
        
        const manager = new FirebaseManager();

        manager.deleteCategory(index, () => {
            const newIndex = index + 1;
            this.deleteCategories(newIndex, callback);
        }); 
    }

    onRenderItemColumn(item, index, column) {
        if(column.fieldName == 'background') {
            return (
                <div className='admin-image' style={{background: `url('${item.background}')`}}></div>
            );
        }
        else if(column.fieldName == 'video') {
            return (
                <video autoPlay={true} loop={true} muted={true} width='200' height='200'>
                    <source src={item.video} />
                </video>
            );
        }
        else {
            return item[column.fieldName];
        }
    }

    getFile() {
        const $this = this;
        const file = document.getElementById('file').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const category = $this.state.category;
            
            category.background = reader.result;
            
            $this.setState(
                {
                    category: category
                }
            )
        };
        
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    
}