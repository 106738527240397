import React, { Component } from 'react';
import calendar from '../images/calendar.png';
import next from '../images/next.png';
import prev from '../images/prev.png';
import '../css/activities.css';
import '../css/filter.css';
import "animate.css";
import $ from 'jquery';
import { Common } from '../utilities/common';

export class Filter extends Component {
    displayName = Filter.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='activities horizontal'>
                <p className='title'>
                    {
                        this.props.title
                    }
                </p>
                {
                    this.state.loading && (
                        <div className="lds-ring small"><div></div><div></div><div></div><div></div></div>
                    )
                }
                {
                    !this.state.loading && this.state.data != null && this.state.data.length > 4 && (
                        <div className='container'>
                            <div className='arrows'>
                                <div className='prev' onClick={(ev) => {
                                    this.setScroll(ev.target, false);
                                }}>
                                    <img src={prev} alt='Anterior' onClick={(ev) => {
                                        $(ev.target).parent().click();
                                    }}/>
                                </div>
                                <div className='next' onClick={(ev) => {
                                    this.setScroll(ev.target, true);
                                }}>
                                    <img src={next} alt='Siguiente'/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className='items'>
                    {
                        this.state.data != null && this.state.data.map((item, index) => {
                            return (
                                <div className='item' key={index}>
                                    <div className='image' style={{background: `url(${Common.getThumbnail(this.props.media, item.featured_media)})`}}></div>
                                    <div className='content'>
                                        <div className='title-container'>
                                            <p className='title'>
                                                {
                                                    item.title.rendered
                                                }
                                            </p>
                                            <p className='date'>
                                                <img src={calendar} className='calendar-icon' alt="Fechas"/>
                                                {
                                                    item.acf.fecha_de_inicio
                                                }
                                            </p>
                                            {
                                                item.acf.fecha_de_fin != null && item.acf.fecha_de_fin != '' && (
                                                    <p className='date end'>
                                                        <img src={calendar} className='calendar-icon' alt="Fechas"/>
                                                        {
                                                            item.acf.fecha_de_fin
                                                        }
                                                    </p>
                                                )
                                            }
                                        </div>
                                        <div className='footer'>
                                            {
                                                item.acf.precio !== null && (
                                                    <p className='price'>
                                                        Precio:&nbsp;
                                                        {
                                                            item.acf.precio
                                                        }
                                                        €
                                                    </p>
                                                )
                                            }
                                            <a href={`/activity?a=${item.id}`} className='more-info'>
                                                Más información
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    getData() {
        console.log(this.props.data);
        let filterData = [];

        filterData = this.props.data.filter((a) => {
            return a.categories.indexOf(this.props.category) > -1 && Common.toDate(a.acf.fecha_de_inicio) > new Date();
        });

        Common.sortByDate(filterData);

        this.setState(
            {
                loading: false,
                data: filterData
            }
        );
    }

    setScroll(target, next) {
        const scrollSize = 318 * 4;
        const items = $(target).closest('.container').next('.items'); 
        const scroll = items.scrollLeft();
        const width = $(items).width();
        let scrollTo = 0;

        if($(target).is('img')) {
            target = $(target).parent();
        }

        console.log(target);

        if(next) {
            scrollTo = scroll + scrollSize;
            if(scrollTo >= width) {
                $(target).hide();
            }
    
            if(scrollTo > 0) {
                $(target).prev('.prev').show();
            }
        }
        else {
            scrollTo = scroll - scrollSize;
            
            if(scrollTo <= 0) {
                $(target).hide();
            }

            if(scrollTo <= width) {
                $(target).next('.next').show();
            }
        }

        $(items).scrollLeft(scrollTo);
    }
}