/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Header } from '../components/header';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import '../css/quienes-somos.css';
import $ from 'jquery';

export class QuienesSomos extends Component {
    displayName = QuienesSomos.name;

    constructor(props) {
        super(props);
        this.state = {
            dataCarousel: [],
            quienesSomos: null,
            media: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='quienes-somos'>
                <Header hideCarousel={true} data={this.state.dataCarousel} media={this.state.media} showMoreInfo={false} showSocial={false}/>
                {
                    this.state.quienesSomos != null && (
                        <div className='image-bg' style={{background: `url(${Common.getImage(this.state.media, this.state.quienesSomos.featured_media)})`}}></div>
                    )
                }
                <div className='content'>
                    <p className='title'>
                        Natur Emoción
                    </p>
                    {
                        this.state.quienesSomos != null && (
                            <div dangerouslySetInnerHTML={{__html: this.state.quienesSomos.content.rendered}}></div>
                        )
                    }
                </div>
            </div>
        );
    }

    getData() {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/posts/148?_fields=id,acf,title,featured_media,content',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            this.getMedia(data.featured_media);
            const dataCarousel = [];
            dataCarousel.push(data);
            this.setState(
                {
                    loading: false,
                    quienesSomos: data,
                    dataCarousel: dataCarousel
                }
            );
        });
    }

    getMedia(id) {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/media/' + id + '?_fields=id,source_url',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            const media = this.state.media;
            media.push(data);
            this.setState(
                {
                    media: media
                }
            )
        });
    }

}