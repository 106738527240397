import React, { Component } from 'react';
import calendar from '../images/calendar.png';
import '../css/carousel.css';
import "animate.css"
import $ from "jquery";
import { Common } from '../utilities/common';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";

const style = {
    background: 'transparent',
    border: 0,
    padding: '0px',
    color: 'white'
};


export class Carousel extends Component {
    displayName = Carousel.name;
    index = 0;

    componentDidMount() {
        console.log('Carrusel');
        this.startCarousel();
    }

    render() {
        return (
            <div className='carousel'>
                {
                    this.props.data != null && this.props.data.length > 0 && this.props.data.map((item, index) => {
                        return(
                            <div className={index === 0 ? 'slide active' : 'slide'} key={index}>
                                <div className='wrapper'>
                                    <div className='slide-image' style={{background: `url(${Common.getImage(this.props.media, item.featured_media)})`}}></div>
                                    <div className='width-wrapper'>
                                        <div className='text'>
                                            {
                                                item.acf.fecha_de_inicio != null && (
                                                    <div className='date'>
                                                        <img src={calendar} className='calendar-icon' alt="Fechas"/>
                                                        <div className='inline'>
                                                            {
                                                                item.acf.fecha_de_inicio
                                                            }
                                                            {
                                                                item.acf.fecha_de_fin != null && item.acf.fecha_de_fin != '' && (
                                                                    <span className='separator'>
                                                                        -
                                                                    </span>
                                                                )
                                                            }
                                                            {
                                                                item.acf.fecha_de_fin != '' && item.acf.fecha_de_fin != null && (
                                                                    item.acf.fecha_de_fin
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className='title animate__animated animate__fadeInUp'>
                                                {
                                                    item.title.rendered
                                                }
                                            </div>
                                            <div className='description animate__animated animate__fadeInUp'></div>
                                            {
                                                (this.props.showMoreInfo == null || this.props.showMoreInfo) && (
                                                    <a className='more-info animate__animated animate__fadeInUp' href={'/activity?a=' + item.id}>Más información</a>
                                                )
                                            }
                                            {
                                                this.props.showSocial != null && this.props.showSocial && (
                                                    <div className='social'>
                                                        <FacebookShareButton url={window.location.href}>
                                                            <FacebookIcon size={32} round={true} />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton url={window.location.href}>
                                                            <TwitterIcon size={32} round={true} />
                                                        </TwitterShareButton>
                                                        <LinkedinShareButton url={window.location.href}>
                                                            <LinkedinIcon size={32} round={true} />
                                                        </LinkedinShareButton>
                                                        <WhatsappShareButton url={window.location.href}>
                                                            <WhatsappIcon size={32} round={true} />
                                                        </WhatsappShareButton>
                                                        <EmailShareButton url={window.location.href}>
                                                            <EmailIcon size={32} round={true} />
                                                        </EmailShareButton>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    startCarousel() {
        if(this.props.data == null || this.props.data.length === 1) {
            return;
        }

        let index = 0;
        setInterval(() => {
            index++;
            if(index === this.props.data.length) {
                index = 0;
            }

            $('.slide').removeClass('active');
            $($('.slide')[index]).addClass('active');
        }, 7000);
    }
}