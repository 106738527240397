/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Header } from '../components/header';
import { Activities } from '../components/activities';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import '../css/activity.css';
import phone from '../images/telephone.png';
import whatsapp from '../images/whatsapp.png';
import mail from '../images/email.png';
import { Gallery } from '../components/gallery';
import $ from 'jquery';

export class Activity extends Component {
    displayName = Activity.name;
    lastIndex = -1;

    constructor(props) {
        super(props);
        this.state = {
          activities: [],
          media: [],
          activity: null,
          booking: null,
          tabindex: 0,
          suggestions: [],
          loading: true
        };
    }

    componentDidMount() {
        this.getData();
        const interval = setInterval(() => {
            if($('.content img').length > 0) {
                $('.content img').on('click', (ev) => {
                    this.openModal($(ev.target).attr('src'));
                });

                clearInterval(interval);
            }
        }, 1000)
    }

    render() {
        return (
            <div>
                <Header data={this.state.activities} media={this.state.media} loading={this.state.loading} showMoreInfo={false} showSocial={true}/>
                {
                    this.state.activity != null && (
                        <div className='activity'>
                            <div className='content' dangerouslySetInnerHTML={{__html: Common.replaceDomain(this.state.activity.content.rendered)}}></div>
                        </div>
                    )
                }
                <div id="lightbox" className="modal">
                    <span className="close cursor" onClick={this.closeModal.bind(this)}>&times;</span>
                    <div className="modal-content">
                        <div className="slide-container"></div>
                    </div>
                </div>
            </div>
        );
    }

    getData() {
        const id = Common.getUrlParam('a');
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/posts/' + id,
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {

            this.getMedia(data.featured_media);

            document.title = data.title.rendered + ' - NaturEmocion';
            const activities = [];
            activities.push(data);
            this.setState(
                {
                    loading: false,
                    activities: activities,
                    activity: data
                }
            )
        });
    }

    getMedia(id) {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/media/' + id + '?_fields=id,source_url',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            const media = this.state.media;
            media.push(data);
            this.setState(
                {
                    media: media
                }
            )
        });
    }

    openModal(image) {
        $("#lightbox").fadeIn('slow', () => {
            this.setImage(image);
        });
    }

    closeModal() {
        $("#lightbox").fadeOut('slow', () => {
            $('.slide-container').css('background-image', 'none');
        });
    }

    setImage(image) {
        console.log('setimage');
        var i = new Image(); 
        const $this = this;

        i.onload = function() {
            const sizes = $this.setSize(i.width, i.height);
            $('.modal-content').width(sizes.width);
            $('.modal-content').height(sizes.height);
            $('.slide-container').css('width', `${sizes.width}px`);
            $('.slide-container').css('height', `${sizes.height}px`);
            
            const interval = setInterval(() => {
                $('.slide-container').css('background-image', 'url(' + image + ')');
                clearInterval(interval);
            }, 1000);
        };

        i.src = image; 
    }

    setSize(w, h) {
        var maxWidth = window.screen.availWidth; // Max width for the image
        var maxHeight = window.screen.availHeight;   // Max height for the image
        var ratio = 0;  // Used for aspect ratio
        var width = w;    // Current image width
        var height = h;  // Current image height

        // Check if the current width is larger than the max
        if(width > maxWidth) {
            ratio = maxWidth / width;   // get ratio for scaling image
            height = height * ratio;    // Reset height to match scaled image
            width = width * ratio;    // Reset width to match scaled image
        }

        // Check if current height is larger than max
        if(height > maxHeight) {
            ratio = maxHeight / height; // get ratio for scaling image
            width = width * ratio;    // Reset width to match scaled image
            height = height * ratio;    // Reset height to match scaled image
        }

        return {
            width: width,
            height: height
        };
    }
}