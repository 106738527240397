import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import { Spinner, SpinnerSize, PrimaryButton, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import { Common } from '../utilities/common';

init("user_X7JZtosKpwLi2yePvz4Sj");


export class Admin extends Component {
    displayName = Admin.name;

    constructor(props) {
        super(props);
        this.state = {
          code: null
        };
    }

    componentDidMount() {
        this.sendEmail();
    }

    render() {
        return (
            <div>
                {
                    this.state.code != null && (
                        <div className='form'>
                            <p>Introduce el código de 4 dígitos que te hemos enviado a filo800@gmail.com.</p>
                            <input type='text' id='code1'/>
                            <input type='text' id='code2'/>
                            <input type='text' id='code3'/>
                            <input type='text' id='code4'/>
                            <br/>
                            <PrimaryButton onClick={this.validateCode.bind(this)}>Verificar código</PrimaryButton>
                            <DefaultButton>Volver a enviar el código</DefaultButton>
                        </div>
                    )
                }
                {
                    this.state.code == null && (
                        <div className='loading'>
                            <Spinner size={SpinnerSize.large} />
                        </div>
                    )
                }
            </div>
        );
    }

    sendEmail() {
        const code = Math.floor(Math.random() * 9999) + 1000;
        const templateParams = {
            message: code
        };
        
        emailjs.send('service_w2wk0yg','adminaccess', templateParams, 'user_X7JZtosKpwLi2yePvz4Sj')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            this.setState(
                {
                    code: code
                }
            );
        }, (err) => {
            console.log('FAILED...', err);
        });
    }

    validateCode() {
        const userCode = $('#code1').val() + $('#code2').val() + $('#code3').val() + $('#code4').val();

        if(userCode == this.state.code) {
            Common.setCookie('authenticated', true, 1);
            const interval = setInterval(() => {
                clearInterval(interval);
                window.location.href = '/admin/data';
            }, 1000)
        }
    }
}