import firebase from "firebase/app";
import "firebase/database";
import { Common } from "./common";
import $ from 'jquery';

export class FirebaseManager {
    constructor() {
        this.posts = [];
        this.getData();
    }

    getData() {
        const ajax = $.ajax(
            {
                url: 'https://naturemocion.com/wp-json/wp/v2/posts',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            this.posts = data;

            console.log(this.posts);
        });
    }

    getActivity(id, callback) {
        const nameRef = this.database.ref(`/activities/${id}`);
        nameRef.get().then((snapshot) => {
            callback(snapshot.val());
        });
    }

    getActivities(callback) {
        callback(null);
    }

    getCategories(callback) {
        callback(null);
    }

    getBooking(callback) {
        const nameRef = this.database.ref(`/booking`);
        nameRef.get().then((snapshot) => {
            callback(snapshot.val());
        });
    }

    getQuienesSomos(callback) {
        const nameRef = this.database.ref(`/quienes-somos`);
        nameRef.get().then((snapshot) => {
            callback(snapshot.val());
        });
    }

    addActivity(activity, callback) {
        this.getActivities((data) => {
            if(data != null) {
                activity.id = data.length;
            }
            else {
                activity.id = '0';
            }

            const nameRef = this.database.ref(`/activities/${activity.id}`);
            nameRef.set(
                activity,
                (error) => {
                    callback(error)
                }
            );
        });
    }

    editActivity(activity, callback) {
        const nameRef = this.database.ref(`/activities/${activity.id}`);
        nameRef.set(
            activity,
            (error) => {
                callback(error)
            }
        );
    }

    deleteActivity(activity, callback) {
        const nameRef = this.database.ref(`/activities/${activity.id}`);
        nameRef.set(
            null,
            (error) => {
                callback(error)
            }
        );
    }

    addCategory(category, callback) {
        this.getCategories((data) => {
            let id = 0;
            if(data != null) {
                id = data.length;
            }

            const nameRef = this.database.ref(`/categories/${id}`);
            nameRef.set(
                category,
                (error) => {
                    callback(error)
                }
            );
        });
    }

    editCategory(category, index, callback) {
        const nameRef = this.database.ref(`/categories/${index}`);
        nameRef.set(
            category,
            (error) => {
                callback(error)
            }
        );
    }

    deleteCategory(index, callback) {
        const nameRef = this.database.ref(`/categories/${index}`);
        nameRef.set(
            null,
            (error) => {
                callback(error)
            }
        );
    }


    editBooking(booking, callback) {
        const nameRef = this.database.ref(`/booking`);
        nameRef.set(
            booking,
            (error) => {
                callback(error)
            }
        );
    }

    editQuienesSomos(data, callback) {
        const nameRef = this.database.ref(`/quienes-somos`);
        nameRef.set(
            data,
            (error) => {
                callback(error)
            }
        );
    }
}