export class Activity {
    constructor() {
        this.category = [];
        this.comments = [];
        this.concepts = [];
        this.content = [];
        this.description = '';
        this.gallery = [];
        this.id = '';
        this.image = '';
        this.links = [];
        this.name = '';
        this.price = '';
        this.schedule = [];
        this.startDate = '';
        this.endDate = '';
        this.highlight = '0'
    }
}

export class GalleryItem {
    constructor() {
        this.name = '';
        this.url = '';
    }
}

export class LinkItem {
    constructor() {
        this.name = '';
        this.link = '';
    }
}

export class ScheduleItem {
    constructor() {
        this.hour = '';
        this.location = '';
        this.name = '';
    }
}