import React, { Component } from 'react';
import "firebase/database";
import { Header } from '../components/header';
import { Categories } from '../components/categories';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import { Filter } from '../components/filter';
import $ from 'jquery';

const manager = new FirebaseManager();
export class Home extends Component {
    displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          media: [],
          activities: [],
          highlightActivities: [],
          categories: [],
          selectedCategory: null,
          loading: true
        };
    }

    componentDidMount() {
        //this.getCategories();
        this.getData();
    }

    render() {
        return (
            <div>
                <Header showHero={true}/>
                <div className='width-wrapper'>
                    <Categories />
                    <div className='separator'></div>
                    {
                        this.state.data != null && this.state.data.length > 0 && (
                            <div className='clearfix'>
                                <div className='activities-container'>
                                    <Filter data={this.state.data} media={this.state.media} category={2} title='Excursiones de 1 día destacadas'/>
                                </div>
                                <div className='activities-container'>
                                    <Filter data={this.state.data} media={this.state.media} category={3} title='Fines de semana destacados'/>
                                </div>
                                <div className='activities-container'>
                                    <Filter data={this.state.data} media={this.state.media} category={4} title='Viajes destacados'/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    getData() {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/posts?per_page=100&orderby=id&order=desc&_fields=id,acf,title,featured_media,categories',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            for(let i = 0; i < data.length; i++) {
                console.log(data);
                this.getMedia(data[i].featured_media);
            }

            this.setState(
                {
                    loading: false,
                    data: data
                }
            )
        });
    }

    getMedia(id) {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/media/' + id,
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            const media = this.state.media;
            media.push(data);
            this.setState(
                {
                    media: media
                }
            )
        });
    }
}