import React, { Component } from 'react';
import { Header } from '../components/header';
import { Activities } from '../components/activities';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';

export class Search extends Component {
    displayName = Search.name;

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          searching: true,
          activities: [],
          highlightActivities: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div>
                <Header data={this.state.highlightActivities} />
                {
                    !this.state.searching && (
                        <div>
                            {
                                this.state.data != null && this.state.data.length > 0 && (
                                    <div className='width-wrapper'>
                                        <div className='activities-container'>
                                            <Activities title={`Se han encontrado ${this.state.data.length} resultados para "${Common.getUrlParam('q')}"`} data={this.state.data}/>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.state.data.length == 0 && (
                                    <div className='width-wrapper'>
                                        <div className='message'>
                                            No se ha encontrado ningún resultado para "{Common.getUrlParam('q')}"
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        );
    }

    getData() {
        const query = Common.getUrlParam('q');
        console.log(query);

        const manager = new FirebaseManager();
        manager.getActivities((data) => {
            const activities = data;
            console.log(activities);

            Common.sortByDate(activities);

            let highlightActivities = activities.filter((a) => {
                return a.highlight === '1';
            });
    
            if (highlightActivities.length == 0) {
                highlightActivities = activities;
            }

            const filterActivities = activities.filter((a) => {
                return a.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || a.description.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            this.setState(
                {
                    data: filterActivities,
                    highlightActivities: highlightActivities,
                    searching: false
                }
            );
        })
    }
}