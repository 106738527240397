/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../css/footer.css';
import phone from '../images/telephone.png';
import whatsapp from '../images/whatsapp.png';
import mail from '../images/email.png';
import instagram from '../images/instagram.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';


const style = {
    background: 'transparent',
    border: 0,
    padding: '0px',
    color: 'white'
};

export class Footer extends Component {
    displayName = Footer.name;

    render() {
        return (
            <div className='footer'>
                <div className='width-wrapper'>
                    <div className='contact'>
                        <div>
                            <p className='title'>
                                Contacto
                            </p>
                            <p>
                                <a href={`tel:646 949 902`}>
                                    <img src={phone} className='icon' alt='646 949 902'/>
                                    646 949 902
                                </a>
                            </p>
                            <p>
                                <a href={`whatsapp://send?text=Mira lo que he encontrado ${window.location.href}`}>
                                    <img src={whatsapp} className='icon' alt='646 949 902'/>
                                    646 949 902
                                </a>
                            </p>
                            <p>
                                <a href={`mailto://naturemocion@outlook.es?subject=Solicitud de información`}>
                                    <img src={mail} className='icon' alt='naturemocion@outlook.es'/>
                                    naturemocion@outlook.es
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className='social'>
                        <div>
                            <p className='title'>
                                Síguenos en
                            </p>
                            <a href='https://www.instagram.com/p/CTmjCOdq-8o/?utm_medium=copy_link' target='_blank' rel='noreferrer'>
                                <img className='icon' src={instagram} alt='Instagram'/> Instagram
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100072220412008' target='_blank' rel='noreferrer'>
                                <img className='icon' src={facebook} alt='Facebook'/> Facebook
                            </a>
                            <a href='https://youtube.com/channel/UCffZrDZvvnKKq5lzGmIqI3A' target='_blank' rel='noreferrer'>
                                <img className='icon' src={youtube} alt='Youtube'/> Youtube
                            </a>
                        </div>
                    </div>
                    <div className='copyright'>
                        <p>&copy; NaturEmocion {new Date().getFullYear()}</p>
                        <p style={{fontSize: '.8em', margin: '0px'}}>Agencia de viajes CICMA N°4230</p>
                    </div>
                </div>
            </div>
        );
    }
}