import './App.css';
import { Layout } from './components/layout';
import { BrowserRouter, Route } from "react-router-dom";
import { Home } from './views/home';
import { ActivitiesView } from './views/activities';
import { Activity } from './views/activity';
import { Search } from './views/search';
import { Admin } from './views/admin';
import { AdminLayout } from './components/adminlayout';
import { AdminData } from './views/admin-data';
import { QuienesSomos } from './views/quienes-somos';
import { AdminCategories } from './views/admin-categories';
import { AdminBooking } from './views/admin-booking';
import { AdminQuienesSomos } from './views/admin-quienes-somos';
import { Cookies } from './views/cookies';

function App() {
  return (
    <div>
      {
        window.location.href.indexOf('/admin') === -1 && (
          <Layout>
            <BrowserRouter>
                <Route path="/" exact component={Home} />
                <Route path="/activities" exact component={ActivitiesView} />
                <Route path="/activity" exact component={Activity} />
                <Route path="/search" exact component={Search} />
                <Route path="/admin" exact component={Admin} />
                <Route path="/quienes-somos" exact component={QuienesSomos} />
                <Route path="/cookies" exact component={Cookies} />
            </BrowserRouter>
          </Layout>
        )
      }
      {
        window.location.href.indexOf('/admin') > -1 && (
          <AdminLayout>
            <BrowserRouter>
                <Route path="/admin" exact component={Admin} />
                <Route path="/admin/data" exact component={AdminData} />
                <Route path="/admin/categories" exact component={AdminCategories} />
                <Route path="/admin/booking" exact component={AdminBooking} />
                <Route path="/admin/quienes-somos" exact component={AdminQuienesSomos} />
            </BrowserRouter>
          </AdminLayout>
        )
      }
    </div>
  );
}

export default App;
