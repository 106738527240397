import React, { Component } from 'react';
import '../css/gallery.css';
import $ from 'jquery';


export class Gallery extends Component {
    displayName = Gallery.name;
    slideIndex = 1;

    render() {
        return (
            <div className='gallery'>
                {
                    this.props.data != null && this.props.item == null && this.props.data.map((link, index) => {
                        return (
                            <div key={index} className="card" style={{background: `url(${link.url})`}} onClick={() => {
                                this.openModal(link.url);
                            }}>
                                {
                                     link.name != '' && (
                                        <div className="description">
                                            {
                                                link.name
                                            }
                                        </div>
                                     )
                                }
                            </div>
                        );
                    })
                }
                {
                    this.props.data != null && this.props.item != null && this.props.data[this.props.item] != null && (
                        <div className="card full-width" style={{background: `url(${this.props.data[this.props.item].url})`}} onClick={() => {
                            this.openModal(this.props.data[this.props.item].url);
                        }}>
                            {
                                this.props.data[this.props.item].name != null && (
                                    <div className="description">
                                        {
                                            this.props.data[this.props.item].name
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                <div id="lightbox" className="modal">
                    <span className="close cursor" onClick={this.closeModal.bind(this)}>&times;</span>
                    <div className="modal-content">
                        <div className="slide-container"></div>
                    </div>
                </div>
            </div>
        );
    }

    openModal(image) {
        $("#lightbox").fadeIn('slow', () => {
            this.setImage(image);
        });
    }

    closeModal() {
        $("#lightbox").fadeOut('slow', () => {
            $('.slide-container').css('background-image', 'none');
        });
    }

    setImage(image) {
        var i = new Image(); 
        const $this = this;

        i.onload = function() {
            const sizes = $this.setSize(i.width, i.height);
            $('.modal-content').width(sizes.width);
            $('.modal-content').height(sizes.height);
            $('.slide-container').css('width', `${sizes.width}px`);
            $('.slide-container').css('height', `${sizes.height}px`);
            
            const interval = setInterval(() => {
                $('.slide-container').css('background-image', 'url(' + image + ')');
                clearInterval(interval);
            }, 1000);
        };

        i.src = image; 
    }

    setSize(w, h) {
        var maxWidth = window.screen.availWidth > 800 ? 1000 : window.screen.availWidth - 50; // Max width for the image
        var maxHeight = window.screen.availHeight > 600 ? 600 : window.screen.availHeight - 50;   // Max height for the image
        var ratio = 0;  // Used for aspect ratio
        var width = w;    // Current image width
        var height = h;  // Current image height

        // Check if the current width is larger than the max
        if(width > maxWidth) {
            ratio = maxWidth / width;   // get ratio for scaling image
            height = height * ratio;    // Reset height to match scaled image
            width = width * ratio;    // Reset width to match scaled image
        }

        // Check if current height is larger than max
        if(height > maxHeight) {
            ratio = maxHeight / height; // get ratio for scaling image
            width = width * ratio;    // Reset width to match scaled image
            height = height * ratio;    // Reset height to match scaled image
        }

        return {
            width: width,
            height: height
        };
    }
}