import React, { Component } from 'react';
import logo from '../images/logo.png';

export class AdminHeader extends Component {
    displayName = AdminHeader.name;

    render() {
        return (
            <div className='admin-header'>
                <div className="admin-logo" onClick={() => {
                        window.location.href = '/';
                    }}>
                    <img src={logo} alt="NaturEmocion" />
                </div>
                <p className='title'>
                ADMINISTRACION
                </p>
                <div className='admin-menu'>
                    <ul>
                        <li>
                            <a href='/admin/data'>Actividades</a>
                        </li>
                        <li>
                            <a href='/admin/categories'>Categorías</a>
                        </li>
                        <li>
                            <a href='/admin/booking'>Información reservas</a>
                        </li>
                        <li>
                            <a href='/admin/quienes-somos'>Quiénes somos</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}