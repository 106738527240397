export class Common {
    static getUrlParam(param, url) {
        let currentUrl = url;
        let paramValue = '';
    
        if(url == null) {
            currentUrl = window.location.href;
        }
    
        const queryString = currentUrl.split('?')[1];
    
        if(queryString != null) {
            const queryStringParameters = queryString.split('&');
    
            for(const entry of queryStringParameters) {
                const dictionaryEntry = entry.split('=');
                if(dictionaryEntry[0].toLowerCase() === param) {
                    paramValue = decodeURIComponent(dictionaryEntry[1]);
                    break;
                }
            }
        }
    
        return paramValue.replace('#', '');
    }

    static sortByDate(array, desc) {
        if(array == null) {
            return array;
        }
        array.sort((a, b) => {
            const aDate = this.toDate(a.acf.fecha_de_inicio);
            const bDate = this.toDate(b.acf.fecha_de_inicio);

            if (aDate.getTime() < bDate.getTime()) {
                return (desc == null || desc == false) ? -1 : 1;
            }

            if (aDate.getTime() > bDate.getTime()) {
                return (desc == null || desc == false) ? 1 : -1;
            }

            return 0;
        });
    }

    static formatContent(text) {
        if (text.indexOf('**') > -1) {
            return this.formatTitle(text);
        }
        else if (text.indexOf('*') > -1) {
            return this.formatText(text);
        }
        
        return text;
    }

    static formatTitle(text) {
        const textToReplace = '**';
        let newText = text;

        let first = true;
        let indexOf = newText.indexOf(textToReplace);

        while (indexOf > -1) {
            if (first) {
                newText = newText.replace(textToReplace, '<span class="title">');
                first = false;
            }
            else {
                newText = newText.replace(textToReplace, '</span>');
                first = true;
            }

            indexOf = newText.indexOf(textToReplace);
        }

        return newText;
    }

    static formatText(text, old) {
        const textToReplace = '*';
        let newText = text;

        let first = true;
        let indexOf = newText.indexOf(textToReplace);

        while (indexOf > -1) {
            if (first) {
                newText = newText.replace(textToReplace, '<b>');
                first = false;
            }
            else {
                newText = newText.replace(textToReplace, '</b>');
                first = true;
            }

            indexOf = newText.indexOf(textToReplace);
        }

        return newText;
    }

    static replaceDomain(text) {
        return text;
    }

    static toDate(text) {
        if(text == null) {
            return null;
        }
        
        const parts = text.split('/');
        return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
    }

    static getImage(arr, id) {
        const media = arr.find((a) => {
            return a.id == id;
        });

        if (media != null) {
            return media.source_url;
        }

        return null;
    }

    static getThumbnail(arr, id) {
        const media = arr.find((a) => {
            return a.id == id;
        });

        if (media != null) {
            return media.media_details.sizes.medium.source_url;
        }

        return null;
    }
}