import React, { Component } from 'react';
import '../css/carousel.css';
import '../css/hero.css';
import "animate.css";
import calendar from '../images/calendar.png';
import { Common } from '../utilities/common';
import $ from 'jquery';

export class Hero extends Component {
    displayName = Hero.name;

    componentDidMount() {
        this.startCarousel();
    }

    render() {
        return (
            <div className='carousel'>
                <div className='slide active'>
                    <div className='wrapper'>
                        <div className='slide-image'></div>
                        <div className='text-container'>
                            <h1 className='title animate__animated animate__fadeInUp'>
                                Natur Emoción
                            </h1>
                            <div className='description animate__animated animate__fadeInUp'>
                                <p>
                                    Rutas y viajes de senderismo en la naturaleza
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    startCarousel() {
        /*let images = [
            'https://techbeasts.com/wp-content/uploads/2016/05/mountain-3d-wallpaper-8.jpg', 
            'https://wallpaperaccess.com/full/1224063.jpg',
            'https://i.pinimg.com/originals/09/7e/b1/097eb16101fa28c0a94e96fb290c8f26.jpg'
        ];*/

        let images = [];

        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/posts/313?_fields=acf',
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            console.log('hero');
            console.log(data.acf);

            if(data.acf.imagen_1 != null && data.acf.imagen_1 != '') {
                images.push(data.acf.imagen_1);
            }

            if(data.acf.imagen_2 != null && data.acf.imagen_2 != '') {
                images.push(data.acf.imagen_2);
            }

            if(data.acf.imagen_3 != null && data.acf.imagen_3 != '') {
                images.push(data.acf.imagen_3);
            }

            if(data.acf.imagen_4 != null && data.acf.imagen_4 != '') {
                images.push(data.acf.imagen_4);
            }

            if(data.acf.imagen_5 != null && data.acf.imagen_5 != '') {
                images.push(data.acf.imagen_5);
            }

            if(images.length == 0) {
                images = [
                    'https://techbeasts.com/wp-content/uploads/2016/05/mountain-3d-wallpaper-8.jpg', 
                    'https://wallpaperaccess.com/full/1224063.jpg',
                    'https://i.pinimg.com/originals/09/7e/b1/097eb16101fa28c0a94e96fb290c8f26.jpg'
                ];
            }

            $('.slide-image').css('background', `url(${images[0]})`);
            $('.slide-image').fadeIn();

            let index = 0;

            setInterval(() => {
                index++;
                if(index === images.length) {
                    index = 0;
                }

                $('.slide-image').fadeOut('fast', () => {
                    $('.slide-image').css('background', `url(${images[index]})`);
                    $('.slide-image').fadeIn();
                });
            }, 7000);
        });
    }
}