import React, { Component } from 'react';
import '../css/categories.css';
import "animate.css"


export class Categories extends Component {
    displayName = Categories.name;

    constructor(props) {
        super(props);
        this.state = {
          data: []
        };
    }

    render() {
        return (
            <div className='categories'>
                <div className='category'>
                    <div className='card' style={{background: `url(https://www.ecestaticos.com/imagestatic/clipping/cf6/348/cf63485e07ceedcf0f104c94cf04bec3/rutas-de-senderismo-por-madrid-para-semana-santa.jpg?mtime=1617110927)`}}>
                        {
                            window.screen.availWidth > 800 && (
                                <video autoPlay={true} loop={true} muted={true}>
                                    <source src="https://static.videezy.com/system/resources/previews/000/033/712/original/hikingwoman1.mp4" />
                                </video>
                            )
                        }
                        <a href='/activities?id=2&cat=Excursiones de 1 día'>
                            Excursiones de 1 día
                        </a>
                    </div>
                </div>
                <div className='category'>
                    <div className='card' style={{background: `url(https://aws.traveler.es/prod/designs/v1/assets/940x627/200192.jpg)`}}>
                        {
                            window.screen.availWidth > 800 && (
                                <video autoPlay={true} loop={true} muted={true}>
                                    <source src="https://static.videezy.com/system/resources/previews/000/045/983/original/stockfootage0072.mp4" />
                                </video>
                            )
                        }
                        <a href='/activities?id=3&cat=Fines de semana y puentes'>
                            Fines de semana y puentes
                        </a>
                    </div>
                </div>
                <div className='category'>
                    <div className='card' style={{background: `url(https://wallpaperaccess.com/full/487823.jpg)`}}>
                        {
                            window.screen.availWidth > 800 && (
                                <video autoPlay={true} loop={true} muted={true}>
                                    <source src="https://static.videezy.com/system/resources/previews/000/050/225/original/Over_Snowe_Mountains_01.mp4" />
                                </video>
                            )
                        }
                        <a href='/activities?id=4&cat=Viajes'>
                            Viajes
                        </a>
                    </div>
                </div>
                <div className='category'>
                    <div className='card' style={{background: `url(https://images.pexels.com/photos/554609/pexels-photo-554609.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)`}}>
                        {
                            window.screen.availWidth > 800 && (
                                <video autoPlay={true} loop={true} muted={true}>
                                    <source src="https://static.videezy.com/system/resources/previews/000/034/473/original/C0048.mp4" />
                                </video>
                            )
                        }
                        <a href='/activities?cat=Actividades realizadas'>
                            Actividades realizadas
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}