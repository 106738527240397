import React, { Component } from 'react';
import { 
    ShimmeredDetailsList, 
    DetailsListLayoutMode,
    CommandBar, 
    Selection, 
    Modal, 
    IconButton, 
    PrimaryButton, 
    DefaultButton, 
    Pivot, 
    PivotItem,
    TextField,
    Dropdown,
    DatePicker,
    DayOfWeek,
    Text,
    ActionButton,
    Stack,
    StackItem,
    Toggle,
    Dialog, 
    DialogType, 
    DialogFooter,
    FontIcon
} from '@fluentui/react';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import '../css/adminlayout.css';
import { Activity, GalleryItem, LinkItem, ScheduleItem } from '../utilities/activity';
import { AdminHeader } from '../components/admin-header';
import $ from 'jquery';

initializeIcons();

const DayPickerStrings = {
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    goToToday: 'Ir a hoy',
    prevMonthAriaLabel: 'Ir al mes anterior',
    nextMonthAriaLabel: 'Ir al mes siguiente',
    prevYearAriaLabel: 'Ir al año anterior',
    nextYearAriaLabel: 'Ir al año siguiente',
    closeButtonAriaLabel: 'Cerrar calendario'
};

export class AdminData extends Component {
    displayName = AdminData.name;
    _selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => this.getSelectionDetails()
        });

        this.state = {
          data: [],
          hasSelectedItems: false,
          disableEdit: true,
          disableDelete: true,
          showNewModal: false,
          showDeleteDialog: false,
          activity: new Activity(),
          modalTitle: '',
          loading: true,
          editMode: false
        };

        const authenticated = Common.getCookie('authenticated');

        if(authenticated == '') {
            window.location.href = '/admin';
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='admin-data'>
                <AdminHeader/>
                <Text variant='xxLargePlus' block className='super-title'>Actividades</Text>
                <CommandBar
                    items={this.getCommandBarItems()}
                />
                <ShimmeredDetailsList
                enableShimmer={this.state.loading}
                items={this.state.data}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={this.onRenderItemColumn.bind(this)}
                columns={
                    [
                        {
                            key: 'name',
                            name: 'Nombre',
                            fieldName: 'name',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'description',
                            name: 'Descripción',
                            fieldName: 'description',
                            minWidth: 300,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'category',
                            name: 'Categoría',
                            fieldName: 'category',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'startDate',
                            name: 'Fecha de inicio',
                            fieldName: 'startDate',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'endDate',
                            name: 'Fecha de fin',
                            fieldName: 'endDate',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        },
                        {
                            key: 'highlight',
                            name: 'Destacado',
                            fieldName: 'highlight',
                            minWidth: 100,
                            isResizable: true,
                            isPadded: true
                        }
                    ]
                }/>
                <Modal
                    containerClassName='modal-large'
                    isOpen={this.state.showNewModal}
                    onDismiss={() => {
                        this.setState({
                            showNewModal: false
                        });
                    }}
                    isBlocking={true}>
                    <div className='modal-header'>
                        <span>
                            {
                                this.state.modalTitle
                            }
                        </span>
                        <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        className='modal-close'
                        onClick={() => {
                            this.setState({
                                showNewModal: false
                            });
                        }}
                        />
                    </div>
                    <div className='modal-content'>
                        <Pivot>
                            <PivotItem headerText='Información general'>
                                <TextField label='Nombre' defaultValue={this.state.activity.name} onChange={(ev, newValue) => {
                                    const activity = this.state.activity;
                                    activity.name = newValue;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    );
                                }}></TextField>
                                <Dropdown
                                    multiSelect={true}
                                    placeholder="Selecciona una opción"
                                    label="Categoría"
                                    defaultSelectedKeys={this.state.activity.category}
                                    options={[
                                        { key: 'Excursiones de 1 día', text: 'Excursiones de 1 día' },
                                        { key: 'Fines de semana y puentes', text: 'Fines de semana y puentes' },
                                        { key: 'Viajes', text: 'Viajes' }
                                    ]}
                                    onChange={(ev, option) => {
                                        const activity = this.state.activity;
                                        const categories = activity.category || [];

                                        if (option.selected) {
                                            categories.push(option.text);
                                        }
                                        else {
                                            const index = categories.indexOf(option.text);
                                            if (index > -1) {
                                                categories.splice(index, 1);
                                            }
                                        }

                                        console.log(categories);

                                        activity.category = categories;

                                        this.setState(
                                            {
                                                activity: activity
                                            }
                                        )
                                    }}
                                />
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DayPickerStrings}
                                    showMonthPickerAsOverlay={true}
                                    placeholder="Seleccione una fecha..."
                                    label='Fecha de inicio'
                                    value={this.state.activity.startDate}
                                    onSelectDate={(date) => {
                                        const activity = this.state.activity;
                                        activity.startDate = date;
                                        this.setState(
                                            {
                                                activity: activity
                                            }
                                        )
                                    }}
                                    formatDate={this._onFormatDate}
                                />
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DayPickerStrings}
                                    showMonthPickerAsOverlay={true}
                                    placeholder="Seleccione una fecha..."
                                    label='Fecha de fin'
                                    value={this.state.activity.endDate}
                                    onSelectDate={(date) => {
                                        const activity = this.state.activity;
                                        activity.endDate = date;
                                        this.setState(
                                            {
                                                activity: activity
                                            }
                                        )
                                    }}
                                    formatDate={this._onFormatDate}
                                />
                                <label>Imagen de fondo</label>
                                {
                                    this.state.activity.image == '' && (
                                        <div className='add-image'>
                                            <IconButton iconProps={{iconName: 'Add'}} onClick={() => {
                                                $(`#mainFile`).click();
                                            }}></IconButton>
                                        </div>
                                    )
                                }
                                {
                                    this.state.activity.image != '' && (
                                        <div className='admin-image' style={{background: `url('${this.state.activity.image}')`}} onClick={() => {
                                            $(`#mainFile`).click();
                                        }}/>
                                    )
                                }
                                <input type='file' id='mainFile' onChange={() => {
                                    this.getFile('mainFile');
                                }}/>
                                <TextField type='number' className='mb-2' label='Precio' defaultValue={this.state.activity.price} onChange={(ev, newValue) => {
                                    const activity = this.state.activity;
                                    activity.price = newValue;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}></TextField>
                                <TextField multiline={true} label='Descripción' defaultValue={this.state.activity.description} onChange={(ev, newValue) => {
                                    const activity = this.state.activity;
                                    activity.description = newValue;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}></TextField>
                                <Toggle 
                                    onText='Destacado' 
                                    offText='No destacado'
                                    defaultChecked={this.state.activity != null && this.state.activity.highlight === '1'}
                                    label='Selecciona esta opción si quieres que la actividad aparezca en los carruseles como actividad destacada'
                                    onChange={(ev, checked) => {
                                        const activity = this.state.activity;
                                        activity.highlight = checked ? '1' : '0';
                                        this.setState(
                                            {
                                                activity: activity
                                            }
                                        )
                                    }}
                                    ></Toggle>
                            </PivotItem>
                            <PivotItem headerText='Contenido'>
                                <Text>
                                    Escribe aquí el contenido principal que explica el programa de la actividad. Utiliza un asterisco (*) para que el texto aparezca en negrita. Utiliza dos asteriscos (**) para que el texto aparezca como un títlo (por ejemplo, **Programa**).
                                </Text>
                                <TextField multiline={true} rows={10} label='Conceptos' defaultValue={this.state.activity.content != null ? this.state.activity.content.join('\n') : ''} onChange={(ev, newValue) => {
                                    const content = newValue;
                                    const arr = content.split('\n');
                                    
                                    const activity = this.state.activity;
                                    activity.content = arr;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}></TextField>
                            </PivotItem>
                            <PivotItem headerText='Horario'>
                                {
                                    this.state.activity.schedule != null && this.state.activity.schedule.map((schedule, index) => {
                                        return (
                                            <div className='mb-2'>
                                                <Stack horizontal horizontalAlign='space-between'>
                                                    <Text variant='mediumPlus'>Horario {index + 1}</Text>
                                                    <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                                                        const activity = this.state.activity;
                                                        const s = activity.schedule;
                                                        s.splice(index, 1);
                                                        activity.schedule = s;

                                                        this.setState(
                                                            {
                                                                activity: activity
                                                            }
                                                        )
                                                    }}/>
                                                </Stack>
                                                <Stack horizontal>
                                                    <StackItem grow={2} style={{marginRight: '5px'}}>
                                                        <TextField label='Nombre' defaultValue={schedule.name} onChange={(ev, newValue) => {
                                                            const s = this.state.activity.schedule[index];
                                                            const activity = this.state.activity;
                                                            
                                                            s.name = newValue;
                                                            activity.schedule[index] = s;
                                                            
                                                            this.setState(
                                                                {
                                                                    activity: activity
                                                                }
                                                            )
                                                        }}></TextField>
                                                    </StackItem>
                                                    <StackItem grow style={{marginRight: '5px'}}>
                                                        <TextField label='Hora' defaultValue={schedule.hour} onChange={(ev, newValue) => {
                                                            const s = this.state.activity.schedule[index];
                                                            const activity = this.state.activity;
                                                            
                                                            s.hour = newValue;
                                                            activity.schedule[index] = s;
                                                            
                                                            this.setState(
                                                                {
                                                                    activity: activity
                                                                }
                                                            )
                                                        }}></TextField>
                                                    </StackItem>
                                                    <StackItem grow={5}>
                                                        <TextField label='Google Maps' defaultValue={schedule.location} onChange={(ev, newValue) => {
                                                            const s = this.state.activity.schedule[index];
                                                            const activity = this.state.activity;
                                                            
                                                            s.location = newValue;
                                                            activity.schedule[index] = s;
                                                            
                                                            this.setState(
                                                                {
                                                                    activity: activity
                                                                }
                                                            )
                                                        }}></TextField>
                                                    </StackItem>
                                                </Stack>
                                            </div>
                                        )
                                    })
                                }
                                <br/>
                                <ActionButton iconProps={{iconName: 'Add'}} onClick={() => {
                                    const activity = this.state.activity;
                                    let schedule = activity.schedule;

                                    if (schedule == null) {
                                        schedule = [];
                                    }

                                    const s = new ScheduleItem();
                                    schedule.push(s);

                                    activity.schedule = schedule;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}>
                                    Agregar horario
                                </ActionButton>
                            </PivotItem>
                            <PivotItem headerText='Incluido en el precio'>
                                <Text>
                                    Especifica aquí lo que incluye el precio (por ejemplo: Alojamiento con media pensión y desayuno, Seguro de viaje, etc.). Escribe cada concepto en una línea nueva.
                                </Text>
                                <TextField multiline={true} rows={10} label='Conceptos' defaultValue={this.state.activity.concepts != null ? this.state.activity.concepts.join('\n') : ''} onChange={(ev, newValue) => {
                                    const concepts = newValue;
                                    const arr = concepts.split('\n');
                                    
                                    const activity = this.state.activity;
                                    activity.concepts = arr;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}></TextField>
                            </PivotItem>
                            <PivotItem headerText='Observaciones'>
                                <Text>
                                    Especifica aquí observaciones a tener en cuenta para realizar la actividad correctamente, por ejemplo, Se ruega encarecidamente puntualidad o Se ruega ir con indumentaria y calzado acorde a la climatología y orografía del lugar. Escribe cada observación en una línea nueva.
                                </Text>
                                <TextField multiline={true} rows={10} label='Conceptos' defaultValue={this.state.activity.comments != null ? this.state.activity.comments.join('\n') : ''} onChange={(ev, newValue) => {
                                    const comments = newValue;
                                    const arr = comments.split('\n');
                                    
                                    const activity = this.state.activity;
                                    activity.comments = arr;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}></TextField>
                            </PivotItem>
                            <PivotItem headerText='Información de interés'>
                                <Text block className='mb-2'>
                                    Especifica aquí la información adicional relacionada con la actividad, como un enlace a una página sobre la naturaleza de la zona o un enlace a la página o información de google maps sobre el alojamiento.
                                </Text>
                                {
                                    this.state.activity.links != null && this.state.activity.links.map((link, index) => {
                                        return (
                                            <div className='mb-2'>
                                                <Stack horizontal horizontalAlign='space-between'>
                                                    <Text variant='mediumPlus'>Información {index + 1}</Text>
                                                    <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                                                        const activity = this.state.activity;
                                                        const s = activity.links;
                                                        s.splice(index, 1);
                                                        activity.lnks = s;

                                                        this.setState(
                                                            {
                                                                activity: activity
                                                            }
                                                        )
                                                    }}/>
                                                </Stack>
                                                <Stack horizontal>
                                                    <StackItem grow={2} style={{marginRight: '5px'}}>
                                                        <TextField label='Nombre' defaultValue={link.name} onChange={(ev, newValue) => {
                                                            const s = this.state.activity.links[index];
                                                            const activity = this.state.activity;
                                                            
                                                            s.name = newValue;
                                                            activity.links[index] = s;
                                                            
                                                            this.setState(
                                                                {
                                                                    activity: activity
                                                                }
                                                            )
                                                        }}></TextField>
                                                    </StackItem>
                                                    <StackItem grow={5}>
                                                        <TextField label='Enlace' defaultValue={link.link} onChange={(ev, newValue) => {
                                                            const s = this.state.activity.links[index];
                                                            const activity = this.state.activity;
                                                            
                                                            s.link = newValue;
                                                            activity.links[index] = s;
                                                            
                                                            this.setState(
                                                                {
                                                                    activity: activity
                                                                }
                                                            )
                                                        }}></TextField>
                                                    </StackItem>
                                                </Stack>
                                            </div>
                                        )
                                    })
                                }
                                <br/>
                                <ActionButton iconProps={{iconName: 'Add'}} onClick={() => {
                                    const activity = this.state.activity;
                                    let links = activity.links;

                                    if(links == null) {
                                        links = [];
                                    }

                                    const s = new LinkItem();
                                    links.push(s);

                                    activity.links = links;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}>Agregar información</ActionButton>
                            </PivotItem>
                            <PivotItem headerText='Galería'>
                                <Text block className='mb-2'>
                                    Especifica aquí imágenes relacionadas con la actividad que se mostrarán como galería en la sección "Información de interés" de la actividad.
                                </Text>
                                {
                                    this.state.activity.gallery != null && this.state.activity.gallery.map((image, index) => {
                                        return (
                                            <div className='gallery-item' key={index}>
                                                {
                                                    image.url !== '' && (
                                                        <div className='admin-image' style={{background: `url('${image.url}')`}} onClick={() => {
                                                            $(`#file${index}`).click();
                                                        }}/>
                                                    )
                                                }
                                                {
                                                    image.url === '' && (
                                                        <div className='add-image'>
                                                            <IconButton iconProps={{iconName: 'Add'}} onClick={() => {
                                                                $(`#file${index}`).click();
                                                            }}></IconButton>
                                                        </div>
                                                    )
                                                }
                                                <input type='file' id={`file${index}`} onChange={() => {
                                                    this.getGalleryFile(index);
                                                }}/>
                                                <div className='buttons'>
                                                    <Text className='title'>Nombre</Text>
                                                    <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                                                        const activity = this.state.activity;
                                                        const s = activity.gallery;
                                                        s.splice(index, 1);
                                                        activity.gallery = s;

                                                        this.setState(
                                                            {
                                                                activity: activity
                                                            }
                                                        )
                                                    }}/>
                                                </div>
                                                <TextField defaultValue={image.name} onChange={(ev, newValue) => {
                                                    const s = this.state.activity.gallery[index];
                                                    const activity = this.state.activity;
                                                    
                                                    s.name = newValue;
                                                    activity.gallery[index] = s;
                                                    
                                                    this.setState(
                                                        {
                                                            activity: activity
                                                        }
                                                    )
                                                }}></TextField>
                                            </div>
                                        )
                                    })
                                }
                                <br/>
                                <ActionButton className='add-button' iconProps={{iconName: 'Add'}} onClick={() => {
                                    const activity = this.state.activity;
                                    let gallery = activity.gallery;
                                    const s = new GalleryItem();

                                    if(gallery == null) {
                                        gallery = [];
                                    }

                                    gallery.push(s);

                                    activity.gallery = gallery;
                                    this.setState(
                                        {
                                            activity: activity
                                        }
                                    )
                                }}>Agregar información</ActionButton>
                            </PivotItem>
                        </Pivot>
                    </div>
                    <div className='modal-footer'>
                        <PrimaryButton text='Guardar' onClick={this.saveActivity.bind(this)}></PrimaryButton>
                        <DefaultButton text='Cancelar' onClick={() => {
                        this.setState({
                            showNewModal: false
                        });
                        }}></DefaultButton>
                    </div>
                </Modal>
                <Dialog
                    hidden={!this.state.showDeleteDialog}
                    onDismiss={() => {
                        this.setState({
                            showDeleteDialog: !this.state.showDeleteDialog
                        });
                    }}
                    dialogContentProps={
                        {
                            type: DialogType.normal,
                            title: 'Eliminar actividades',
                            subText: '¿Deseas eliminar las actividades seleccionadas?'
                        }
                    }
                    modalProps={
                        {
                            isBlocking: true
                        }
                    }
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            this.deleteActivities(0, () => {
                                this.getData();
                            });
                        }} text="Eliminar" />
                        <DefaultButton onClick={() => {
                            this.setState({
                                showDeleteDialog: !this.state.showDeleteDialog
                            });
                        }} text="Cancelar" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    getCommandBarItems() {
        return [
            {
                key: 'newItem',
                text: 'Nuevo',
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    this.setState(
                        {
                            activity: new Activity(),
                            modalTitle: 'Nueva actividad',
                            editMode: false,
                            showNewModal: true
                        }
                    )
                }
            },
            {
                key: 'editItem',
                text: 'Editar',
                iconProps: { iconName: 'Edit' },
                disabled: this.state.disableEdit,
                onClick: () => {
                    this.setState(
                        {
                            modalTitle: 'Editar actividad',
                            editMode: true,
                            activity: this.parseActivity(this._selection.getSelection()[0]),
                            showNewModal: true
                        }
                    )
                }
            },
            {
                key: 'delete',
                text: 'Eliminar',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    this.setState(
                        {
                            showDeleteDialog: !this.state.showDeleteDialog
                        }
                    )
                },
                disabled: this.state.disableDelete
            },
            {
                key: 'view',
                text: 'Ver en la web',
                iconProps: { iconName: 'View' },
                onClick: () => {
                    window.open(`/activity?a=${this._selection.getSelection()[0].id}`)
                },
                disabled: this.state.disableEdit
            }
        ]
    }

    getSelectionDetails() {
        this.setState({
            hasSelectedItems: this._selection.getSelectedCount() > 0,
            disableEdit: this._selection.getSelectedCount() !== 1,
            disableDelete: this._selection.getSelectedCount() === 0
        });
    }

    getData() {
        this.setState({
            loading: true
        });
        
        const manager = new FirebaseManager();
        manager.getData((data) => {
            const activities = Object.assign([], data.activities);
            const parsedActivities = [];
            
            for(let i = 0; i < activities.length; i++) {
                if (activities[i] != null) {
                    parsedActivities.push(activities[i]);
                }
            }

            this.setState(
                {
                    data: parsedActivities,
                    showNewModal: false,
                    showDeleteDialog: false,
                    activity: new Activity(),
                    loading: false
                }
            );
        })
    }

    _onFormatDate (date) {
        if(date != null) {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
    
          return `${day}/${month}/${year}`;
        }
        else {
          return '';
        }
    }

    saveActivity() {
        const activity = this.state.activity;
        
        if(activity.price != '') {
            activity.price = `${activity.price}€`;
        }

        if (activity.startDate != null && activity.startDate != '') {
            activity.startDate = activity.startDate.toLocaleDateString();
        }

        if (activity.endDate != null && activity.endDate != '') {
            activity.endDate = activity.endDate.toLocaleDateString();
        }

        if(activity.endDate == '') {
            activity.endDate = null;
        }
        
        const manager = new FirebaseManager();
        
        if (!this.state.editMode) {
            manager.addActivity(activity, () => {
                this.getData();
            }); 
        }
        else {
            manager.editActivity(activity, () => {
                this.getData();
            }); 
        }
    }

    parseActivity(activity) {
        const parsedActivity = Object.assign({}, activity);

        if (parsedActivity.startDate != null && parsedActivity.startDate != '') {
            parsedActivity.startDate = Common.toDate(parsedActivity.startDate);
        }

        if (parsedActivity.endDate != null && parsedActivity.endDate != '') {
            parsedActivity.endDate = Common.toDate(parsedActivity.endDate);
        }

        if (parsedActivity.price != null && parsedActivity.price != '') {
            parsedActivity.price = parseInt(parsedActivity.price);
        }

        return parsedActivity;
    }

    deleteActivities(index, callback) {
        const activity = this._selection.getSelection()[index];
        
        if (activity == null) {
            callback();
            return;
        }

        console.log(activity);
        
        const manager = new FirebaseManager();

        manager.deleteActivity(activity, () => {
            const newIndex = index + 1;
            this.deleteActivities(newIndex, callback);
        }); 
    }

    onRenderItemColumn(item, index, column) {
        if(column.key == 'highlight') {
            if(item.highlight === '1') {
                return (
                    <Text variant='mediumPlus' block style={{textAlign: 'center'}}>
                        <FontIcon iconName='SkypeCheck'/>
                    </Text>
                );
            }
            else {
                return '';
            }
        }
        if(column.key === 'category' && item.category != null) {
            return item.category.join(', ');
        }
        else {
            return item[column.fieldName];
        }
    }

    getGalleryFile(index) {
        const $this = this;
        const file = document.getElementById('file' + index).files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const s = $this.state.activity.gallery[index];
            const activity = $this.state.activity;
            
            s.url = reader.result;
            activity.gallery[index] = s;
            
            $this.setState(
                {
                    activity: activity
                }
            )
        };
        
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    getFile(id) {
        const $this = this;
        const file = document.getElementById(id).files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const activity = $this.state.activity;
            
            activity.image = reader.result;
            
            $this.setState(
                {
                    activity: activity
                }
            )
        };
        
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    
}