import React, { Component } from 'react';
import '../css/activities.css';
import "animate.css";
import calendar from '../images/calendar.png';
import { Common } from '../utilities/common';
import $ from 'jquery';


export class Activities extends Component {
    displayName = Activities.name;

    render() {
        return (
            <div className='activities'>
                {
                    this.props.data != null && this.props.data.length > 0 && (
                        <div className='dk-ml-40'>
                            <p className='title'>
                                {
                                    this.props.title
                                }
                            </p>
                            <div className='items'>
                                {
                                    this.props.data != null && this.props.data.map((item, index) => {
                                        return (
                                            <div className='item' key={index}>
                                                <div className='image'style={{background: `url(${Common.getThumbnail(this.props.media, item.featured_media)})`}}></div>
                                                <div className='content'>
                                                    <div className='title-container'>
                                                        <p className='title'>
                                                            {
                                                                item.title.rendered
                                                            }
                                                        </p>
                                                        <p className='date'>
                                                            <img src={calendar} className='calendar-icon' alt="Fechas"/>
                                                            {
                                                                item.acf.fecha_de_inicio
                                                            }
                                                        </p>
                                                        {
                                                            item.acf.fecha_de_fin != null && item.acf.fecha_de_fin != '' && (
                                                                <p className='date end'>
                                                                    <img src={calendar} className='calendar-icon' alt="Fechas"/>
                                                                    {
                                                                        item.acf.fecha_de_fin
                                                                    }
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='footer'>
                                                        {
                                                            item.acf.precio != '' && item.acf.precio != null && (
                                                                <p className='price'>
                                                                    Precio:&nbsp;
                                                                    {
                                                                        item.acf.precio
                                                                    }
                                                                    €
                                                                </p>
                                                            )
                                                        }
                                                        <a href={`/activity?a=${item.id}`} className='more-info'>
                                                            Más información
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}