import React, { Component } from 'react';
import '../css/adminlayout.css';

export class AdminLayout extends Component {
    displayName = AdminLayout.name;

    render() {
        return (
            <div className='admin-layout'>
                {
                    this.props.children
                }
            </div>
        );
    }
}