import React, { Component } from 'react';
import logo from '../images/logo.png';
import search from '../images/search.png';
import loading from '../images/loading.gif';
import { Carousel } from './carousel';
import '../css/header.css';
import $ from 'jquery';
import { Hero } from './hero';

export class Header extends Component {
    displayName = Header.name;

    render() {
        return (
            <div className='header'>
                <div className='width-wrapper'>
                    <div className="logo" onClick={() => {
                            window.location.href = '/';
                        }}>
                        <img src={logo} alt="NaturEmocion" />
                    </div>
                    <div className="sidenav" id="sidenav">
                        <a href="#" className="closebtn" onClick={this.closeNav.bind(this)}>&times;</a>
                        <a href='/quienes-somos'>Quiénes somos</a>
                        <a href="/activities?id=2&cat=Excursiones de 1 día">Excursiones de 1 día</a>
                        <a href="/activities?id=3&cat=Fines de semana y puentes">Fines de semana y puentes</a>
                        <a href="/activities?id=4&cat=Viajes">Viajes</a>
                        <a href="/activities?cat=Actividades realizadas">Actividades realizadas</a>
                    </div>
                    <div className='menu'>
                        <a href='#' onClick={this.openNav.bind(this)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </div>
                </div>
                {
                    this.props.showHero && (
                        <Hero media={this.props.media}></Hero>
                    )
                }
                {
                    !this.props.showHero && !this.props.hideCarousel && !this.props.loading && this.props.data != null && this.props.media != null && (
                        <Carousel data={this.props.data} media={this.props.media} showMoreInfo={this.props.showMoreInfo} showSocial={this.props.showSocial}/>
                    )
                }
                {
                    !this.props.showHero && !this.props.hideCarousel && this.props.loading && (
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    )
                }
            </div>
        );
    }

    search() {
        const query = $('#searchBox').val();
        window.location.href = `/search?q=${query}`;
    }

    openNav() {
        if(window.screen.availWidth > 768) {
            document.getElementById("sidenav").style.width = "30%";
        }
        else {
            document.getElementById("sidenav").style.width = "100%";
        }
    }
      
    closeNav() {
        document.getElementById("sidenav").style.width = "0";
    }
}