import React, { Component } from 'react';
import { 
    TextField,
    Text,
    PrimaryButton,
    DefaultButton} from '@fluentui/react';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import '../css/adminlayout.css';
import { AdminHeader } from '../components/admin-header';
import { Booking } from '../utilities/booking';

initializeIcons();

export class AdminBooking extends Component {
    displayName = AdminBooking.name;

    constructor(props) {
        super(props);

        this.state = {
          booking: new Booking(),
          editMode: false
        };

        const authenticated = Common.getCookie('authenticated');

        if(authenticated == '') {
            window.location.href = '/admin';
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='admin-data'>
                <AdminHeader/>
                <Text variant='xxLargePlus' block className='super-title'>Información de reservas</Text>
                <div className='mini-form'>
                    <TextField disabled={!this.state.editMode} label='Teléfono' value={this.state.booking.phone} onChange={(ev, newValue) => {
                        const booking = this.state.booking;
                        booking.phone = newValue;
                        booking.whatsapp = newValue;
                        this.setState(
                            {
                                booking: booking
                            }
                        );
                    }}></TextField>
                    <TextField disabled={!this.state.editMode} label='Email' value={this.state.booking.mail} onChange={(ev, newValue) => {
                        const booking = this.state.booking;
                        booking.mail = newValue;
                        this.setState(
                            {
                                booking: booking
                            }
                        );
                    }}></TextField>
                    <br/>
                    <div>
                    <PrimaryButton style={{display: this.state.editMode ? 'none' : 'inline-block'}} onClick={() => {
                        this.setState({
                            editMode: true
                        });
                    }}>Modificar</PrimaryButton>
                    <PrimaryButton style={{display: this.state.editMode ? 'inline-block' : 'none'}} onClick={this.saveBooking.bind(this)}>Guardar</PrimaryButton>
                    <DefaultButton style={{display: this.state.editMode ? 'inline-block' : 'none'}} className='ml-2' onClick={() => {
                        this.setState({
                            editMode: false
                        });
                    }}>Cancelar</DefaultButton>
                    </div>
                </div>
            </div>
        );
    }

    getData() {
        const manager = new FirebaseManager();
        manager.getBooking((data) => {
            console.log(data);

            this.setState(
                {
                    booking: data,
                    editMode: false
                }
            );
        });
    }

    saveBooking() {
        const booking = this.state.booking;
        
        const manager = new FirebaseManager();
        
        manager.editBooking(booking, () => {
            this.getData();
        }); 
    }

    updateCategory(activities, oldCategory, newCategory, index) {
        let activity = activities[index];
        
        if(index == activities.length || activity == null) {
            return;
        }

        for(let i = 0; i < activity.category.length; i++) {
            const indexOf = activity.category.indexOf(oldCategory);

            if(indexOf > -1) {
                const categories = activity.category;
                categories.splice(indexOf, 1);
                categories.push(newCategory);
                activity.category = categories;
                console.log(activity);
                break;
            }
        }

        const manager = new FirebaseManager();
        manager.editActivity(activity, () => {
            const newIndex = index + 1;
            this.updateCategory(activities, oldCategory, newCategory, newIndex);
        });
    }

    deleteCategories(index, callback) {
        const category = this._selection.getSelection()[index];
        
        if (category == null) {
            callback();
            return;
        }

        console.log(category);
        
        const manager = new FirebaseManager();

        manager.deleteCategory(index, () => {
            const newIndex = index + 1;
            this.deleteCategories(newIndex, callback);
        }); 
    }

    onRenderItemColumn(item, index, column) {
        if(column.fieldName == 'background') {
            return (
                <div className='admin-image' style={{background: `url('${item.background}')`}}></div>
            );
        }
        else if(column.fieldName == 'video') {
            return (
                <video autoPlay={true} loop={true} muted={true} width='200' height='200'>
                    <source src={item.video} />
                </video>
            );
        }
        else {
            return item[column.fieldName];
        }
    }

    getFile() {
        const $this = this;
        const file = document.getElementById('file').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const category = $this.state.category;
            
            category.background = reader.result;
            
            $this.setState(
                {
                    category: category
                }
            )
        };
        
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    
}