import React, { Component } from 'react';
import "firebase/database";
import { Header } from '../components/header';
import { Activities } from '../components/activities';
import { FirebaseManager } from '../utilities/firebase';
import { Common } from '../utilities/common';
import $ from 'jquery';

export class ActivitiesView extends Component {
    displayName = ActivitiesView.name;

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          highlightActivities: [],
          loading: true,
          media: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div>
                <Header data={this.state.highlightActivities} media={this.state.media} loading={this.state.loading}/>
                <div className='width-wrapper'>
                    <div className='activities-container'>
                        <Activities media={this.state.media} title={Common.getUrlParam('cat')} data={this.state.data}/>
                    </div>
                </div>
            </div>
        );
    }

    getData() {
        const id = Common.getUrlParam('id');

        let url = 'https://wordpress.naturemocion.com/wp-json/wp/v2/posts?per_page=100&orderby=id&order=desc&_fields=id,acf,title,featured_media';

        if (id != null && id != '') {
            url += '&categories=' + id;
        }

        const ajax = $.ajax(
            {
                url: url,
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            for(let i = 0; i < data.length; i++) {
                console.log(data[i].featured_media)
                this.getMedia(data[i].featured_media);
            }

            const filterData = data.filter((a) => {
                if (id != null && id != '') {
                    return Common.toDate(a.acf.fecha_de_inicio) >= new Date();
                }
                else {
                    return Common.toDate(a.acf.fecha_de_inicio) < new Date();
                }
            });

            Common.sortByDate(filterData, Common.getUrlParam('cat').toLowerCase() == 'actividades realizadas');

            let highlightActivities = filterData.filter((a) => {
                return a.acf.destacado === 'Sí';
            });
    
            if (highlightActivities.length == 0) {
                highlightActivities = filterData;
            }

            this.setState(
                {
                    loading: false,
                    data: filterData,
                    highlightActivities: highlightActivities
                }
            );
        });
    }

    getMedia(id) {
        const ajax = $.ajax(
            {
                url: 'https://wordpress.naturemocion.com/wp-json/wp/v2/media/' + id,
                type: "GET",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }
        );

        ajax.done((data) => {
            const media = this.state.media;
            media.push(data);
            this.setState(
                {
                    media: media
                }
            )
        });
    }
}